import axios from "axios";
import { Navigate } from "react-router-dom";

const token = localStorage.getItem("mg-u-tkn");

const axiosInstance = axios.create({
  baseURL: "https://api-beta-prod.mongoro.com/api/v1",
  headers: {
    common: {
      "Content-Type": "application/json",
      "x-token": token,
      "x-signature": "01b84ea3ad0db216ed65cf8bdca060419ec71ea5",
    },
  },
});

axiosInstance.interceptors.response.use(
  (response) => handleSuccess(response),
  (error) => handleError(error)
);

function handleSuccess(response) {
  return response;
}

function handleError(error) {
  if (error.response && error.response.code === 401) {
    Navigate({ to: "/login", replace: true });
  }

  throw error;
}

export default axiosInstance;
