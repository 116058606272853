import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { KycManager } from "../../context-api/KycContext";
import { variableManager } from "../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";
import "../../styles/uploadImage.scss";
import axiosInstance from "../../utils/axios_instance";
export default function UploadImageForm() {
  const { msg, setMsg, moreDetails, handleGetUser } =
    useContext(variableManager);
  const { profile, setProfile } = useContext(KycManager);
  const navigate = useNavigate();
  const [image, setImage] = useState({ toDisplay: "", image: "" });

  const config = { headers: { "content-type": "multipart/form-data" } };

  function handleImageChange(e) {
    setMsg({ ...msg, spinner: false });
    setImage({
      ...image,
      toDisplay: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  }
  async function handleUploadImage() {
    setMsg({ ...msg, spinner: true });
    const formdata = new FormData();
    formdata.append(
      "id",
      moreDetails.id ? moreDetails.id : localStorage.getItem("mongoro_user")
    );

    formdata.append("file", image.image);
    formdata.append("upload_preset", "mongoro_user_profile_image");

    if (profile.imageUpload) {
      await axios
        .post(
          `https://api.cloudinary.com/v1_1/verifrica/image/upload`,
          formdata
        )
        .then(async (resp) => {
          try {
            await axiosInstance
              .put(`/user/edit`, {
                image: resp.data.secure_url,
              })
              .then(() => {
                setMsg({ ...msg, spinner: false });
                setProfile({ ...profile, imageUpload: false });
              });

            handleGetUser();
          } catch (error) {
            setMsg({ ...msg, spinner: false });
          }
        });
    } else {
      try {
        setMsg({ ...msg, spinner: true, error: "" });

        await axios
          .post(
            `https://api.cloudinary.com/v1_1/verifrica/image/upload`,
            formdata
          )
          .then(async (resp) => {
            try {
              await axiosInstance
                .put(`/user/edit`, { image: resp.data.secure_url })
                .then(() => {
                  setMsg({ ...msg, spinner: false });

                  navigate("/signup/success");
                  handleGetUser();
                });
            } catch (error) {
              setMsg({ ...msg, spinner: false });
            }
          });
      } catch (error) {
        setMsg({
          ...msg,
          spinner: false,
          error: error.message || error.response.data.message,
        });
        setTimeout(() => {
          setMsg({ ...msg, spinner: false, error: "" });
        }, 2000);
      }
    }
  }

  return (
    <div className="upload-image-container create-account-container">
      <div className="form-area">
        {/* <span className="signup-redirect-link hide-on-modal">Already have an account ?  <b className="pointer"> &nbsp; Log in</b></span> */}

        <div className="content">
          <h2>Image Upload</h2>
          <p className="mantra mb-3">Upload image</p>

          {image.toDisplay ? (
            <div>
              <div className="file-input-area">
                <label className="label" htmlFor="upload-image">
                  <img src={image.toDisplay} alt="profile" />
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-image"
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="file-input-area">
                <label className="label" htmlFor="upload-image">
                  <p className="drag">Drag and drop here</p>
                  <p>or</p>
                  <p className="text-primary">Browse files</p>
                </label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="upload-image"
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                />
              </div>
            </div>
          )}
          <p className="mt-3">Accepted format: jpeg, PNG</p>
          {msg.error && <div className="error">{msg.error}</div>}
          <button
            className={image.toDisplay ? "mt-5" : "mt-5 button"}
            onClick={(e) => {
              e.preventDefault();
              handleUploadImage();
            }}
            disabled={msg.spinner ? "{true}" : ""}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
